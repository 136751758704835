<template>
  <b-card>
    <div>
      <!-- search input -->
      <div class="custom-search d-flex align-items-center justify-content-end">
        <div
          class="d-flex flex-column flex-sm-row align-items-center mb-1 justify-content-around"
        >
          <b-form-input
            v-on:keyup="onSearch"
            v-model.lazy="searchTerm"
            placeholder="Search..."
            type="text"
            class="d-inline-block mr-sm-1 mb-1 mb-sm-0"
          />
          <template
            v-if="$permissionAbility(SALARY_POLICY_CREATE, permissions)"
          >
            <b-button
              class="flex-shrink-0"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              v-on:click="showModal"
            >
              Create
            </b-button>
          </template>
        </div>
      </div>

      <!-- table -->
      <vue-good-table
          styleClass="vgt-table table-custom-style striped"
        :line-numbers="false"
        mode="remote"
        @on-page-change="onPageChange"
        @on-sort-change="onSortChange"
        @on-column-filter="onColumnFilter"
        @on-per-page-change="onPerPageChange"
        :totalRows="totalRecords"
        :isLoading.sync="isLoading"
        :rows="rows"
        :columns="columns"
        :sort-options="{
          enabled: false,
          multipleColumns: true,
          initialSortBy: [{ field: 'created_at', type: 'desc' }],
        }"
        :pagination-options="{
          enabled: true,
          perPage: pageLength,
        }"

      >
        <template slot="table-row" slot-scope="props">
          <!-- Basic % -->
          <template v-if="props?.column?.field === 'format_title'">
            <div>
              <span>
                <b class="cursor-pointer" v-if="$permissionAbility(SALARY_POLICY_SHOW, permissions)" v-on:click="onShowPage(props.row)">{{ props?.row?.title }} </b>
                <b v-else>{{ props?.row?.title }} </b>
              </span>
            </div>
          </template>

          <template v-if="props?.column?.field === 'format_basic'">
            <div>
              <span class="font-weight-bold">{{ props?.row?.basic }} % </span>
            </div>
          </template>

          <!-- House Rent % -->
          <template v-if="props?.column?.field === 'format_house_rent'">
            <div>
              <span class="font-weight-bold">{{ props?.row?.house_rent }} % </span>
            </div>
          </template>

          <!-- Conveyance % -->
          <template v-if="props?.column?.field === 'format_conveyance'">
            <div>
              <span class="font-weight-bold">{{ props?.row?.conveyance }} % </span>
            </div>
          </template>

          <!-- Medical % -->
          <template v-if="props?.column?.field === 'format_medical'">
            <div>
              <span class="font-weight-bold">{{ props?.row?.medical }} % </span>
            </div>
          </template>

          <!-- Entertainment % -->
          <template v-if="props?.column?.field === 'format_entertainment'">
            <div>
              <span class="font-weight-bold">{{ props?.row?.entertainment }} % </span>
            </div>
          </template>

          <!-- Column: Action -->
          <span v-if="props.column.field === 'action'">
            <span>
              <b-dropdown
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
              >
                <template v-slot:button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="text-body align-middle mr-25"
                  />
                </template>
                <template
                  v-if="$permissionAbility(SALARY_POLICY_EDIT, permissions)"
                >
                  <b-dropdown-item v-on:click="onShow(props.row)">
                    <feather-icon icon="Edit2Icon" class="mr-50" />
                    <span>Edit</span>
                  </b-dropdown-item>
                </template>

                <template
                  v-if="$permissionAbility(SALARY_POLICY_SHOW, permissions)"
                >
                  <b-dropdown-item v-on:click="onShowPage(props.row)">
                    <feather-icon icon="EyeIcon" class="mr-50" />
                    <span>Show</span>
                  </b-dropdown-item>
                </template>

                <template
                  v-if="$permissionAbility(SALARY_POLICY_DELETE, permissions)"
                >
                  <b-dropdown-item v-on:click="onDelete(props.row.id)">
                    <feather-icon icon="TrashIcon" class="mr-50" />
                    <span>Delete</span>
                  </b-dropdown-item>
                </template>
              </b-dropdown>
            </span>
          </span>

          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap"> Showing 1 to </span>
              <b-form-select
                v-model="pageLength"
                :options="['10', '25', '50', '100', '500']"
                class="mx-1"
                @input="
                  (value) => props.perPageChanged({ currentPerPage: value })
                "
              />
              <span class="text-nowrap"> of {{ props.total }} entries </span>
            </div>
            <div>
              <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value) => props.pageChanged({ currentPage: value })"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </div>

    <b-modal
      id="modal-salary-policy-form"
      centered
      :title="
        modelType == 'editModel' ? 'Edit Salary Policy' : 'Create Salary Policy'
      "
      hide-footer
      @hidden="hiddenModal"
      no-close-on-backdrop
    >
      <validation-observer ref="salaryPolicyValidation">
        <b-form v-on:submit.prevent="validationForm">
          <!-- Policy title -->
          <b-form-group
            label="Salary Policy Name"
            label-for="title"
            class="required-label"
          >
            <validation-provider
              #default="{ errors }"
              name="salary policy title"
              vid="title"
              rules="required|max:255"
            >
              <b-form-input
                id="title"
                type="text"
                v-model="salaryPolicyName"
                :state="errors.length > 0 ? false : null"
                placeholder="Salary Policy Name"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- Basic percentage -->
          <b-form-group
            label="Basic (%)"
            label-for="basic"
            class="required-label"
          >
            <validation-provider
              #default="{ errors }"
              name="basic"
              vid="basic"
              rules="required|max:255"
            >
              <b-form-input
                id="basic"
                type="number"
                v-model="salaryPolicyBasic"
                :state="errors.length > 0 ? false : null"
                placeholder="Basic Salary %"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-row>
            <b-col md="6">
              <!-- House Rent Percentage -->
              <b-form-group
                label="House Rent (%)"
                label-for="house_rent"
                class="required-label"
              >
                <validation-provider
                  #default="{ errors }"
                  name="house rent"
                  vid="house_rent"
                  rules="required|max:255"
                >
                  <b-form-input
                    id="house_rent"
                    type="number"
                    v-model="salaryPolicyHouseRent"
                    :state="errors.length > 0 ? false : null"
                    placeholder="House Rent %"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <!-- Conveyance Percentage -->
              <b-form-group
                label="Conveyance (%)"
                label-for="conveyance"
                class="required-label"
              >
                <validation-provider
                  #default="{ errors }"
                  name="conveyance"
                  vid="conveyance"
                  rules="required|max:255"
                >
                  <b-form-input
                    id="conveyance"
                    type="number"
                    v-model="salaryPolicyConveyance"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Conveyance %"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col md="6">
              <!-- Medical Percentage -->
              <b-form-group
                label="Medical (%)"
                label-for="medical"
                class="required-label"
              >
                <validation-provider
                  #default="{ errors }"
                  name="medical"
                  vid="medical"
                  rules="required|max:255"
                >
                  <b-form-input
                    id="medical"
                    type="number"
                    v-model="salaryPolicyMedical"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Medical %"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <!-- Entertainment Percentage -->
              <b-form-group
                label="Dearness Allowance (%)"
                label-for="entertainment"
                class="required-label"
              >
                <validation-provider
                  #default="{ errors }"
                  name="DearnessAllowance"
                  vid="DearnessAllowance"
                  rules="required|max:255"
                >
                  <b-form-input
                    id="entertainment"
                    type="number"
                    v-model="salaryPolicyEntertainment"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Dearness Allowance %"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

          <!-- loading button -->
          <template v-if="isSalaryPolicyFormSubmitLoading">
            <b-button class="float-right" variant="primary" disabled>
              <b-spinner small />
              Loading...
            </b-button>
          </template>

          <!-- submit button -->
          <template v-else>
            <b-button
              type="submit"
              class="float-right"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
            >
              Submit
            </b-button>
          </template>
        </b-form>
      </validation-observer>
    </b-modal>

    <b-modal
      id="modal-policy-assign-user"
      centered
      title="Assign Policy To User"
      hide-footer
      @hidden="hiddenPolicyAssignModal"
      no-close-on-backdrop
    >
      <validation-observer ref="assignUserPolicy">
        <b-form v-on:submit.prevent="assignUserPolicyForm">
          <!-- Policy title -->
          <b-form-group
            label="Salary Policy Name"
            label-for="title"
            class="required-label"
          >
            <validation-provider
              #default="{ errors }"
              name="salary policy title"
              vid="title"
              rules="required|max:255"
            >
              <b-form-input
                id="title"
                type="text"
                v-model="salaryPolicyName"
                :state="errors.length > 0 ? false : null"
                disabled
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- Basic percentage -->
          <b-form-group
            label="Basic (%)"
            label-for="basic"
            class="required-label"
          >
            <validation-provider
              #default="{ errors }"
              name="basic"
              vid="basic"
              rules="required|max:255"
            >
              <b-form-input
                id="basic"
                type="number"
                v-model="salaryPolicyBasic"
                :state="errors.length > 0 ? false : null"
                disabled
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-row>
            <b-col md="6">
              <!-- House Rent Percentage -->
              <b-form-group
                label="House Rent (%)"
                label-for="house_rent"
                class="required-label"
              >
                <validation-provider
                  #default="{ errors }"
                  name="house rent"
                  vid="house_rent"
                  rules="required|max:255"
                >
                  <b-form-input
                    id="house_rent"
                    type="number"
                    v-model="salaryPolicyHouseRent"
                    :state="errors.length > 0 ? false : null"
                    disabled
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <!-- Conveyance Percentage -->
              <b-form-group
                label="Conveyance (%)"
                label-for="conveyance"
                class="required-label"
              >
                <validation-provider
                  #default="{ errors }"
                  name="conveyance"
                  vid="conveyance"
                  rules="required|max:255"
                >
                  <b-form-input
                    id="conveyance"
                    type="number"
                    v-model="salaryPolicyConveyance"
                    :state="errors.length > 0 ? false : null"
                    disabled
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col md="6">
              <!-- Medical Percentage -->
              <b-form-group
                label="Medical (%)"
                label-for="medical"
                class="required-label"
              >
                <validation-provider
                  #default="{ errors }"
                  name="medical"
                  vid="medical"
                  rules="required|max:255"
                >
                  <b-form-input
                    id="medical"
                    type="number"
                    v-model="salaryPolicyMedical"
                    :state="errors.length > 0 ? false : null"
                    disabled
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <!-- Entertainment Percentage -->
              <b-form-group
                label="Entertainment (%)"
                label-for="entertainment"
                class="required-label"
              >
                <validation-provider
                  #default="{ errors }"
                  name="entertainment"
                  vid="entertainment"
                  rules="required|max:255"
                >
                  <b-form-input
                    id="entertainment"
                    type="number"
                    v-model="salaryPolicyEntertainment"
                    :state="errors.length > 0 ? false : null"
                    disabled
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

          <UserAssignPolicyForm
            @userAssignFromDataEvent="handleUserAssignFromData"
          />
          <template v-if="isUserPolicyAssignFormSubmitLoading">
            <b-button class="float-right" variant="primary" disabled>
              <b-spinner small />
              Loading...
            </b-button>
          </template>

          <!-- submit button -->
          <template v-else>
            <b-button
              type="submit"
              class="float-right"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
            >
              Assign
            </b-button>
          </template>
        </b-form>
      </validation-observer>
    </b-modal>
  </b-card>
</template>

<script>
import {
  BCard,
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BButton,
  BForm,
  BModal,
  BSpinner,
  BRow,
  BCol,
  VBTooltip,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, max } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { mapGetters } from "vuex";
import UserAssignPolicyForm from "@/views/admin/payroll-management/UserAssignPolicyForm.vue";
import {
  SALARY_POLICY_CREATE,
  SALARY_POLICY_EDIT,
  SALARY_POLICY_DELETE,
  SALARY_POLICY_SHOW,
} from "@/helpers/permissionsConstant";

export default {
  name: "SalaryPolicyView",
  components: {
    UserAssignPolicyForm,
    BForm,
    BButton,
    BCard,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    ValidationProvider,
    ValidationObserver,
    BModal,
    BSpinner,
    BRow,
    BCol,
    VBTooltip,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      // Permissions
      SALARY_POLICY_CREATE,
      SALARY_POLICY_EDIT,
      SALARY_POLICY_DELETE,
      SALARY_POLICY_SHOW,

      modelType: "",
      name: "",

      // salary policy
      policyCategoryId: "",
      salaryPolicyId: "",
      salaryPolicyName: "",
      salaryPolicyBasic: "",
      salaryPolicyHouseRent: "",
      salaryPolicyConveyance: "",
      salaryPolicyMedical: "",
      salaryPolicyEntertainment: "",
      policyAssignData: "",
      pageLength: 10,
      columns: [
        {
          label: "Name",
          field: "format_title",
          sortable: false,
        },
        {
          label: "Basic (%)",
          field: "format_basic",
          sortable: false,
        },
        {
          label: "House Rent (%)",
          field: "format_house_rent",
          sortable: false,
        },
        {
          label: "Conveyance (%)",
          field: "format_conveyance",
          sortable: false,
        },
        {
          label: "Medical (%)",
          field: "format_medical",
          sortable: false,
        },
        {
          label: "Dearness Allowance (%)",
          field: "format_entertainment",
          sortable: false,
        },

        {
          label: "Created On",
          field: "created_at",
          formatFn: this.formatDateTime,
          sortable: true,
        },
        {
          label: "Action",
          field: "action",
          sortable: false,
        },
      ],
      rows: [],
      searchTerm: "",
      delayTimer: null,
      isLoading: false,
      isSalaryPolicyFormSubmitLoading: false,
      isUserPolicyAssignFormSubmitLoading: false,
      totalRecords: 0,
      serverParams: {
        columnFilters: {},
        sort: [
          { field: "id", type: "desc" },
          { field: "created_at", type: "desc" },
        ],
        page: 1,
        perPage: 10,
      },
    };
  },

  computed: {
    ...mapGetters({
      permissions: "userModule/getPermissions",
    }),
    statusVariant() {
      const statusColor = {
        true: "light-success",
        false: "light-danger",
      };

      return (status) => statusColor[status];
    },
  },

  async created() {
    try {
      this.loadItems();
    } catch (error) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          icon: "BellIcon",
          variant: "danger",
          text: error?.response?.data?.message,
        },
      });
    }
  },

  methods: {
    handleUserAssignFromData(data) {
      this.policyAssignData = data;
    },
    showModal() {
      this.$bvModal.show("modal-salary-policy-form");
    },

    showAssignUserModal() {
      this.$bvModal.show("modal-policy-assign-user");
    },

    hiddenPolicyAssignModal() {
      this.$bvModal.hide("modal-policy-assign-user");
    },

    hiddenModal() {
      this.$bvModal.hide("modal-salary-policy-form");
      this.resetModal();
    },

    resetModal() {
      this.modelType = "";
      this.salaryPolicyId = "";
      this.selectStatusValue = "";
      this.policyCategoryId = "";

      this.salaryPolicyName = "";
      this.salaryPolicyBasic = "";
      this.salaryPolicyHouseRent = "";
      this.salaryPolicyConveyance = "";
      this.salaryPolicyMedical = "";
      this.salaryPolicyEntertainment = "";
    },

    onShow(value) {
      this.modelType = "editModel";
      this.salaryPolicyId = value.id;

      this.salaryPolicyName = value.title;
      this.salaryPolicyBasic = value.basic;
      this.salaryPolicyHouseRent = value.house_rent;
      this.salaryPolicyConveyance = value.conveyance;
      this.salaryPolicyMedical = value.medical;
      this.salaryPolicyEntertainment = value.entertainment;

      this.showModal();
    },

    onShowPage(value) {
      let policyCategoryName = "salary-policy";

      let policyCategoryId = value?.policy_category_id;
      let id = value?.id;
      this.$router.push({
        name: "admin-payroll-policy-assign-details",
        params: { policyCategoryName, policyCategoryId, id },
      });
    },

    onShowAssignUserModal(value) {
      this.policyCategoryId = value?.policy_category_id;
      this.salaryPolicyId = value?.id;
      this.salaryPolicyName = value?.title;
      this.salaryPolicyBasic = value?.basic;
      this.salaryPolicyHouseRent = value?.house_rent;
      this.salaryPolicyConveyance = value?.conveyance;
      this.salaryPolicyMedical = value?.medical;
      this.salaryPolicyEntertainment = value?.entertainment;

      this.showAssignUserModal();
    },

    formatDateTime(value) {
      if (value) {
        return this.$moment(value).format("MMM Do YYYY");
      }
    },

    async onDelete(id) {
      this.$swal({
        title: "Warning!",
        text: "Are You Sure You Want To Delete This?",
        icon: "warning",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: "Ok",
        showLoaderOnConfirm: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await this.$api.delete(`/api/salary-policies/${id}`);

            this.loadItems();

            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Success",
                icon: "BellIcon",
                variant: "success",
                text: "Salary Policy Successfully Deleted",
              },
            });
          } catch (error) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Error",
                icon: "BellIcon",
                variant: "danger",
                text: error?.response?.data?.message,
              },
            });
          }
        }
      });
    },

    onSearch() {
      if (this.delayTimer) {
        clearTimeout(this.delayTimer);
        this.delayTimer = null;
      }

      this.delayTimer = setTimeout(() => {
        this.loadItems();
      }, 1000);
    },

    async getSalaryPolicyItems(params) {
      return await this.$api.get("api/salary-policies?include=policyCategory", {
        params: {
          show: params.show,
          page: params.page,
          sort: params.sort,
          q: params.q,
        },
      });
    },

    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
      this.loadItems();
    },

    onColumnFilter(params) {
      this.updateParams(params);
      this.loadItems();
    },

    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage });
      this.loadItems();
    },

    onSortChange(params) {
      this.updateParams({
        sort: params,
      });
      this.loadItems();
    },

    async loadItems() {
      try {
        const salaryPolicies = await this.getSalaryPolicyItems({
          show: this.serverParams.perPage,
          page: this.serverParams.page,
          sort: this.serverParams.sort,
          q: this.searchTerm,
        });

        const data = salaryPolicies?.data?.data;
        const meta = salaryPolicies?.data?.meta;

        this.totalRecords = meta?.pagination?.total;
        this.rows = data;
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Warning",
            icon: "BellIcon",
            variant: "warning",
            text: error?.response?.data?.message,
          },
        });
      }
    },
    validationForm: async function () {
      this.$refs.salaryPolicyValidation.validate().then(async (success) => {
        if (success) {
          try {
            this.$refs.salaryPolicyValidation.reset();
            if (this.modelType == "editModel") {
              this.isSalaryPolicyFormSubmitLoading = true;
              await this.$api.put(
                `/api/salary-policies/${this.salaryPolicyId}`,
                {
                  title: this.salaryPolicyName,
                  basic: this.salaryPolicyBasic,
                  house_rent: this.salaryPolicyHouseRent,
                  conveyance: this.salaryPolicyConveyance,
                  medical: this.salaryPolicyMedical,
                  entertainment: this.salaryPolicyEntertainment,
                }
              );
              this.isSalaryPolicyFormSubmitLoading = false;
              this.loadItems();

              this.hiddenModal();

              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Success",
                  icon: "BellIcon",
                  variant: "success",
                  text: "Salary Policy Successfully Updated",
                },
              });
            } else {
              this.isSalaryPolicyFormSubmitLoading = true;
              await this.$api.post("/api/salary-policies", {
                title: this.salaryPolicyName,
                basic: this.salaryPolicyBasic,
                house_rent: this.salaryPolicyHouseRent,
                conveyance: this.salaryPolicyConveyance,
                medical: this.salaryPolicyMedical,
                entertainment: this.salaryPolicyEntertainment,
              });
              this.isSalaryPolicyFormSubmitLoading = false;
              this.hiddenModal();

              this.loadItems();

              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Success",
                  icon: "BellIcon",
                  variant: "success",
                  text: "Salary Policy Successfully Created",
                },
              });
            }
          } catch (error) {
            this.isSalaryPolicyFormSubmitLoading = false;
            if (error?.response?.data?.message) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Error",
                  icon: "BellIcon",
                  variant: "danger",
                  text: error?.response?.data?.message,
                },
              });
            }

            if (error?.response?.data?.errors) {
              this.$refs.salaryPolicyValidation.setErrors(
                error?.response?.data?.errors
              );
            }
          }
        }
      });
    },

    assignUserPolicyForm: async function () {
      this.$refs.assignUserPolicy.validate().then(async (success) => {
        if (success) {
          try {
            this.$refs.assignUserPolicy.reset();

            this.isUserPolicyAssignFormSubmitLoading = true;

            await this.$api.post("/api/user-assign-policy", {
              policy_category_id: this.policyCategoryId,
              policy_id: this.salaryPolicyId,
              applied_to: this.policyAssignData?.appliedTo,
              is_all_department_selected:
                this.policyAssignData?.isAllDeptSelected,
              is_all_user_selected: this.policyAssignData?.isAllUserSelected,
              department_ids: this.policyAssignData?.departmentIds,
              except_user_ids: this.policyAssignData?.exceptUserIds,
              user_ids: this.policyAssignData?.userIds,
            });

            this.isUserPolicyAssignFormSubmitLoading = false;
            this.hiddenPolicyAssignModal();
            this.loadItems();

            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Success",
                icon: "BellIcon",
                variant: "success",
                text: "Policy Assigned Successfully ",
              },
            });
          } catch (error) {
            this.isUserPolicyAssignFormSubmitLoading = false;
            if (error?.response?.data?.message) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Error",
                  icon: "BellIcon",
                  variant: "danger",
                  text: error?.response?.data?.message,
                },
              });
            }

            if (error?.response?.data?.errors) {
              this.$refs.assignUserPolicy.setErrors(
                error?.response?.data?.errors
              );
            }
          }
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";

.required-label label::after {
  content: " *";
  color: red;
}
</style>

